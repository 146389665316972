<template>
    <div>
        <div class="card-toolbar mb-5">

<!--            <router-link v-if="$can('delimiters.create')" to="/settings/delimiters/create"  class="btn btn-primary font-weight-bolder"><v-icon>mdi-plus</v-icon>-->
<!--              {{ $t('delimiters.new_delimiter') }}-->
<!--            </router-link>-->
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20" v-if="showAdvancedSearch">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_name">{{$t('delimiters.name')}}</label>
                            <input v-model="filters.name" type="text" id="f_name" class="form-control">
                        </div>


                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.is_active" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <b-button class="mt-auto mb-0 mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mb-0 mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!--begin::User-->
        <div class="card card-custom">
            
            <div class="card-body">
                <div class="mb-5">
                    <div class="d-flex justify-content-end">
                        <custom-export-data v-if="$can('delimiters.export_data')" :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.Prices_lists')"></custom-export-data>
                    </div>
                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <b-form-checkbox v-if="$can('delimiters.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                        <b-form-checkbox v-else size="lg" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                    </template>
<!--                    <template slot="actions" slot-scope="props">-->
<!--                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')"  v-if="$can('delimiters.update')" color="blue darken-2" @click="editItem(props.row)">mdi-pencil</v-icon>-->
<!--                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('delimiters.delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>-->
<!--                    </template>-->
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-aspects",
        components: {},
        data() {
            return {
                mainRoute: 'settings/tax_rules_details',
                subMainRoute: 'settings/tax_rules_detail',
                // mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    name: '',
                    is_active: '',
                },

                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                // columns: ['name', 'created_at', 'status', 'actions'],
                columns: ['name', 'created_at', 'status'],


                disabledButton: false,
                validation: [],
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('delimiters.name')] = 'name';
                fields[this.$t('created_at')] = 'created_at';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('delimiters.name'),
                        created_at: that.$t('created_at'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },

        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.delimiters")}]);
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = '';
                this.filters.is_active = '';

                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(item) {
                this.$router.push({name: 'delimiters.edit', params: {id: item.id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            changeStatus(id, status) {
                ApiService.patch(this.subMainRoute + '/change-status/' + id, {
                    is_active: (status ? '1' : '0'),
                }).then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },

        },
    };
</script>
